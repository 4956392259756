<template>
	<div v-show="!showModal">
		<a-spin :spinning="loading">
			<cTable ref="cTable" isPage isRequest :requestFun="getAgreementSettingList" :columns="columns">
				<template #bodyCell="{ column, record }">
					<template v-if="column.key == 'action'">
						<a-dropdown>
							<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon></a-button>
							<template #overlay>
								<a-menu>
									<div @click="toSee(record)">
										<a-menu-item>
											<a>查看</a>
										</a-menu-item>
									</div>
									<div @click="toEdit(record)">
										<a-menu-item>
											<a>编辑</a>
										</a-menu-item>
									</div>
								</a-menu>
							</template>
						</a-dropdown>
					</template>
				</template>
			</cTable>
		</a-spin>
	</div>
	<temp v-if="showModal" :agKey="agKey" :isSee="isSee" @back="onBack"></temp>
</template>

<script>
	import temp from './temp.vue';
	import {
		Icon
	} from "@/components/icon/icon.js";
	import cTable from '@/components/cTable/index.vue';
	import { getAgreementSettingList } from '@/service/modules/operation.js';
	export default {
		components: {
			Icon,
			cTable,
			temp
		},
		data() {
			return {
				loading: false,
				showModal: false,
				isSee: false,
				agKey: '',
				list: [],
				columns: [{
					title: '协议',
					dataIndex: 'title',
				}, {
					title: '操作',
					key: 'action',
					width: 300
				}],
			}
		},
		mounted() {
			this.getData();
		},
		methods: {
			getAgreementSettingList: getAgreementSettingList,
			onBack() {
				this.showModal = false;
				this.getData();
			},
			getData() {
				this.$refs.cTable.toQuery();
			},
			toEdit(item) {
				this.agKey = item.key;
				this.isSee = false;
				this.showModal = true;
			},
			toSee(item) {
				this.agKey = item.key;
				this.isSee = true;
				this.showModal = true;
			}
		}
	}
</script>

<style>
</style>