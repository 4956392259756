<template>
	<div>
		<Header :title="isSee ? '查看协议' : '编辑协议'" @back="onBack"></Header>
		<a-spin :spinning="loading">
			<a-form style="margin-top: 20px;" ref="form" name="form" :model="modelRef" scrollToFirstError @finish="onSubmit" :labelCol="{span: 4}" :wrapperCol="{span: 14}">
			    <a-form-item label="标题">
			        {{ modelRef.title }}
			    </a-form-item>
			
				<a-form-item name="content" :rules="[{ required: true, message: '必填项不允许为空' }]" label="描述图文">
					<div style="position: relative;z-index: 99;">
					    <Editor v-model:value="modelRef.content" :configSetting="{ disabled: isSee }"></Editor>
					</div>
				</a-form-item>
			
			    <a-row>
					<a-col :offset="6">
						 <a-button v-if="!isSee" type="primary" html-type="submit">提交</a-button>
						 <a-button style="margin-left: 20px;" @click="onBack">返回</a-button>
					</a-col>
				</a-row>
			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
	import Editor from '@/components/editor/editor';
	import { getAgreementSettingDetail, updateAgreementSetting } from '@/service/modules/operation.js';
	export default {
		components: {Header, Editor},
		props: {
			agKey: {
				type: String,
				default: ''
			},
			isSee: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				loading: false,
				modelRef: {
					title: '',
					content: ''
				}
			}
		},
		created() {
			if (this.agKey) {
				this.getData();
			}
		},
		methods: {
			onBack() {
				this.$emit('back');
			},
			async getData() {
				this.loading = true;
				let ret = await getAgreementSettingDetail({
					key: this.agKey
				})
				this.loading = false;
				if (ret.code === 200) {
					this.modelRef = ret.data;
				}
			},
			async onSubmit() {
				this.loading = true;
				let ret = await updateAgreementSetting(this.modelRef);
				this.loading = false;
				if (ret.code === 200) {
					this.$message.success('修改成功！')
					this.onBack()
				}
			},
		}
	}
</script>

<style>
</style>
